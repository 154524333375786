.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

:root {
  --guess-correct-color: green;
  --guess-wrong-color: red;
  --background-color-light: #e5e4ed; /* Off-white background for light mode */
  --text-color-light: #000; /* Black text for light mode */
  --background-color-dark: #041014; /* Dark background for dark mode */
  --text-color-dark: #fff; /* White text for dark mode */
  --shared-width: 29vw;
}

/* Default theme */
body {
  background-color: var(--background-color-light);
  color: var(--text-color-light);
  padding-top: env(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
}

/* Dark theme */
body.dark-mode {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

.image-container {
  width: var(--shared-width);
  height: 9.666vw; /* Set height to be 1/3 of the width (70vw / 3) */
  overflow: hidden; /* Hide parts of the image outside the container */
  display: flex; /* Use flexbox to center the image */
  justify-content: center;
  align-items: center; /* Center the image vertically and horizontally */
  margin: 0 auto; /* Center the container horizontally */
  position: relative; /* For absolute positioning of children, if necessary */
  border: 2px solid #235c6c; /* Example border, adjust as needed */
}

.image-container img {
  width: 100%; /* Make the image fill the container width */
  /* border: 2px solid #235c6c; Example border, adjust as needed */
  box-sizing: border-box; /* Ensures padding doesn't add to the width */
}

.full-image-container {
  width: 29vw;
  display: flex; /* Use flexbox to center the image */
  justify-content: center;
  align-items: center; /* Center the image vertically and horizontally */
  margin: 0 auto; /* Center the container horizontally */
  position: relative; /* For absolute positioning of children, if necessary */
}

.full-image-container img {
  width: 100%; /* Make the image fill the container width */
  height: auto; /* Adjust height automatically to maintain aspect ratio */
  border: 2px solid #235c6c; /* Example border, adjust as needed */
  box-sizing: border-box; /* Ensures padding doesn't add to the width */
}

.imageSourceContainer {
  position: absolute; /* Updated: Position absolutely within its relative parent */
  bottom: 0; /* Align to the bottom of the image-container */
  width: 100%; /* Match the parent width */
  background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent background */
  color: #fff; /* White text color for visibility */
  text-align: center; /* Center the text */
  padding: 10px; /* Add some padding */
  box-sizing: border-box; /* Ensures padding doesn't add to the width */
}

.imageSource {
  color: #ffffff; /* Ensure text is visible against the background */
  text-decoration: none; /* Optional: removes underline from links */
  display: block; /* Ensures the link fills the container for easier clicking */
}

/* Update to ensure the dots are displayed below the image */
.dots {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adjust space above the dots as needed */
  margin-bottom: 30px;
}

.dot {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1001;
  position: fixed;
  width: 50vw;
  max-height: 75vh;
  overflow-y: auto;
}

.searchBox {
  display: flex;
  width: var(--shared-width);
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  background: #2f3640;
  border-radius: 50px;
  position: relative;
  margin-top: 10px;
}
.searchBox.show-rectangle::after {
  content: "";
  position: absolute;
  bottom: 0; /* Adjust if necessary to align with the bottom of the searchBox */
  left: 0;
  right: 0;
  height: 33px; /* Height of your rectangle */
  background-color: #2f3640; /* Light mode color */
  z-index: -1; /* Behind the searchBox content */
  top: 33px;
}

/* search/guess styling */
.searchButton {
  color: white;
  position: absolute;
  top: 6px;
  right: 8px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(
    --gradient-2,
    linear-gradient(90deg, #2af598 0%, #009efd 100%)
  );
  border: 0;
  display: inline-block;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.suggestions {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1001; /* Make sure it's above other content */
  background-color: #2f3640;
  width: var(--shared-width);
  border-top: none;

  color: white; /* Light mode text */
  border-radius: 5px;
  overflow: hidden; /* Ensures the border-radius applies to children */
}

.suggestions li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease; /* Smooth background transition */
}

.suggestions li:hover {
  background-color: #444;
}

/* hover effect
button:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.5) 0 10px 20px;
  transform: translateY(-3px);
}
/* button pressing effect*/
/* button:active {
  box-shadow: none;
  transform: translateY(0);
}  */

.searchInput {
  border: none;
  background: none;
  outline: none;
  color: white;
  font-size: 16px;
  padding: 22px 43px 22px 24px;
  width: 22vw;
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.logo {
  width: 34vw;
  height: auto;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* Adjust padding as necessary */
  width: 100%;
}

.guesses-list {
  padding-bottom: 40px;
  /* font-family: "Micro 5", sans-serif;
  font-weight: 400;
  font-style: normal; */
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.imageSource {
  font-size: 10px;
}

.answerText {
  font-size: 25px;
}

.guesses-list div {
  color: var(--text-color-light); /* Default to light mode text color */
  font-size: 16px;
  border: 1px solid #ddd; /* Adds a subtle outline */
  padding: 10px; /* Adds some padding inside the boxes */
  margin-bottom: 5px; /* Adds space between each guess */
  border-radius: 5px; /* Optional: rounds the corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow for depth */
}

.guesses-remaining {
  margin-top: 20px;
  font-size: 18px;
  color: var(--text-color-light); /* This should change based on the theme */
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.guesses-remaining.dark {
  color: var(--text-color-dark); /* Or simply #fff if not using variables */
}

body.dark-mode .guesses-list div {
  color: var(--text-color-dark); /* Dark mode text color */
}

/* Styles for correct (checkmark) and wrong (x) guesses */
.guess-correct {
  color: var(--guess-correct-color);
}

.guess-wrong {
  color: var(--guess-wrong-color);
}

.multiple-choice-container {
  margin-top: 10px;
  width: var(--shared-width);
  display: flex;
  flex-direction: column;
}

.who-btn {
  background-color: #369b7b;
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
  /* padding: 0.15em 0.4em; */
  /* font-weight: 900; */
  /* font-size: 18px; */
  border: 3px solid black;
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover and active states */
  color: black; /* Explicit text color */
  border-color: black; /* Explicit border color */
  -webkit-appearance: none; /* Remove default browser styling */
}

.multiple-choice-btn {
  background: #3ccdb9;
  font-family: inherit;
  padding: 0.6em 1.3em;
  font-weight: 900;
  font-size: 18px;
  border: 3px solid black;
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover and active states */
  color: black; /* Explicit text color */
  border-color: black; /* Explicit border color */
  -webkit-appearance: none; /* Remove default browser styling */
}

.multiple-choice-btn:hover {
  transform: translate(-0.05em, -0.05em);
  box-shadow: 0.15em 0.15em;
}

.multiple-choice-btn:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em;
}

.switch {
  font-size: 17px;
  /* position: relative; */
  display: inline-block;
  width: 64px;
  height: 34px;
  position: relative;
  top: 5px;
  right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3ccdb9;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  border-radius: 20px;
  left: 2px;
  bottom: 2px;
  z-index: 2;
  background-color: #e8e8e8;
  transition: 0.4s;
}

.sun svg {
  position: absolute;
  top: 6px;
  left: 36px;
  z-index: 1;
  width: 24px;
  height: 24px;
}

.moon svg {
  fill: #73c0fc;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  width: 24px;
  height: 24px;
}

/* .switch:hover */
.sun svg {
  animation: rotate 15s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* .switch:hover */
.moon svg {
  animation: tilt 5s linear infinite;
}

@keyframes tilt {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-10deg);
  }

  75% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.input:checked + .slider {
  background-color: #1e5148;
}

.input:focus + .slider {
  box-shadow: 0 0 1px #183153;
}

.input:checked + .slider:before {
  transform: translateX(30px);
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.message-box {
  /* width: 27vw; Adjust based on your design */
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px; /* Rounded corners */
  color: var(--text-color-light);
  font-size: 18px; /* Larger text */
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body.dark-mode .message-box {
  color: var(--text-color-dark);
}

.correct {
  border: 2px solid green;

  box-shadow: 0 0 8px green;
}

.incorrect {
  border: 2px solid red;

  box-shadow: 0 0 8px red;
}

.message {
  font-weight: bold; /* Make the main message bold */
  margin-bottom: 5px; /* Space between the main message and the 'It was' part */
  font-size: 1.5rem;
  margin-top: 5px;
}

.answer {
  margin: 5px;
  font-size: 1.5rem;
}

.answer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer-btn-container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
}

.top-row {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  padding: 10px 0;
  /* border-bottom: 1px solid var(--text-color-light); */
  /* background-color: #a8deca; */
  margin-bottom: 5px;
}

.top-row-left {
  flex: 0 0 30%;
}

.logo-container {
  flex: 0 0 40%;
  display: flex;
  justify-content: center;
}

.header-options {
  display: flex;
  justify-content: flex-end;
  flex: 0 0 30%;
  position: relative;
  align-items: center;

  margin-right: 20px;
}

.archive-btn-container {
  display: flex;
  width: 50vw;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.back-archive-btn {
  background-color: #369b7b;
  font-family: inherit;
  border: 3px solid rgb(0, 0, 0);
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover and active states */
  color: black; /* Explicit text color */
  border-color: black; /* Explicit border color */
  -webkit-appearance: none; /* Remove default browser styling */
  margin-bottom: 10px;
  padding: 5px;
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.archive-db-btn.clicked {
  background-color: #235c6c; /* Replace #yourColorHere with the color you want */
}

.archive-db-btn {
  /* Adjust margins if needed, especially if using alongside other buttons */
  /* margin-right: 10px; */
  /* background: #3ccdb9; */
  background-color: #369b7b;
  font-family: inherit;
  /* padding: 0.15em 0.4em; */
  width: 10vw;
  height: 40px;
  /* font-weight: 900; */
  /* font-size: 18px; */
  border: 3px solid black;
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover and active states */
  color: black; /* Explicit text color */
  border-color: black; /* Explicit border color */
  -webkit-appearance: none; /* Remove default browser styling */
}

.archive-btn {
  background-color: #369b7b;
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
  /* padding: 0.15em 0.4em; */
  width: 100px;
  height: 40px;
  /* font-weight: 900; */
  /* font-size: 18px; */
  border: 3px solid black;
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover and active states */
  color: black; /* Explicit text color */
  border-color: black; /* Explicit border color */
  -webkit-appearance: none; /* Remove default browser styling */
}

.about-btn {
  /* Adjust margins if needed, especially if using alongside other buttons */
  /* margin-right: 10px; */
  /* background: #3ccdb9; */
  background-color: #369b7b;
  font-family: inherit;
  /* padding: 0.15em 0.4em; */
  width: 42px;
  height: 40px;
  /* font-weight: 900; */
  /* font-size: 18px; */
  border: 3px solid black;
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover and active states */
  color: black; /* Explicit text color */
  border-color: black; /* Explicit border color */
  -webkit-appearance: none; /* Remove default browser styling */
  /* margin-right: 15px; */
}

.about-icon {
  height: 100%;
  width: 100%;
}

/* Dark theme adjustments */
body.dark-mode .modal-content {
  background-color: #333; /* Dark background for the modal */
  color: #fff; /* Light text color for visibility */
}

/* If you have specific elements within .modal-content that need color adjustment, target them here */
body.dark-mode .modal-content p,
body.dark-mode .modal-content a {
  color: #fff; /* Ensure all text inside the modal is visible in dark mode */
}

.app-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f0f0f0; /* Adjust based on your theme */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
  margin-right: 10px;
}

.footer-container {
  width: 100%;
}
body.dark-mode .app-footer {
  background-color: black;
  box-shadow: 0 -2px 5px rgba(251, 250, 250, 0.1); /* Optional: adds a subtle shadow */
}

.privacy-policy {
  background: none;
  color: var(--text-color-light);
  border: none;
  padding-right: 15px;
  font: inherit; /* Makes the button font consistent with surrounding text */
  cursor: pointer;
  text-decoration: underline;
}

body.dark-mode .privacy-policy {
  color: var(--text-color-dark);
}

.privacy-policy-modal {
  text-align: left;
}

.share-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #1e5148;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  cursor: pointer;
  border: none;
  margin: 5px;
  /* width: 50%; */
}

.share-button:after {
  content: " ";
  width: 0%;
  height: 100%;
  background: #369b7b;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
}
.share-button:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}

.share-span {
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding: 10px;
  color: #fff;
  font-size: 1.125em;
  letter-spacing: 0.2em;
  z-index: 20;
  transition: all 0.3s ease-in-out;
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.link-to-archive {
  text-decoration: none;
}

.guesses-list-title {
  margin-bottom: 10px;
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 10px;
}

.share-span:hover {
  color: #183153;
  animation: scaleUp 0.3s ease-in-out;
}

.share-span.tight-spacing {
  /* letter-spacing: -0.05em; Adjusted letter spacing */
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

.game-title {
  margin-bottom: 10px;
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.archive-title {
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 10px;
}

.stats-display {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.stats-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.stats-item {
  font-size: 14px;
  margin-bottom: 5px;
}










/* Media Queries For Responsiveness */

@media (max-width: 1020px) {
  .imageSourceContainer {
    width: 39vw;
  }
  .imageSource {
    font-size: 8px;
  }
  .image-container {
    width: 39vw;
    height: 13vw;
  }
  .full-image-container {
    width: 39vw;
  }
  .searchBox {
    width: 39vw;
  }
  .message-box {
    width: 37vw;
  }
  .suggestions {
    width: 39vw;
  }
  .logo {
    width: 40vw;
  }
  .searchInput {
    width: 28vw;
  }
  .multiple-choice-container {
    width: 39vw;
  }
  .answer {
    font-size: 1rem;
  }
  .who-btn {
    font-size: 0.7rem;
  }
}

@media (max-width: 650px) {
  .imageSourceContainer {
    width: 52vw;
  }
  .imageSource {
    font-size: 6px;
  }
  .image-container {
    width: 70vw;
    height: 23.33vw;
  }
  .full-image-container {
    width: 52vw;
  }
  .searchBox {
    width: 70vw;
  }
  .message-box {
    width: 48vw;
    font-size: 12px;
  }
  .message {
    font-size: 1rem;
  }
  .answer {
    font-size: 1rem;
  }
  .modal-content {
    width: 80vw;
  }
  .suggestions {
    width: 70vw;
  }
  .header {
    flex-direction: column;
  }
  .logo {
    width: 90vw;
  }
  .logo-container {
    justify-content: center;
    flex: none; /* This removes the flex: 1 on smaller screens */
  }
  .top-row {
    flex-wrap: wrap;
    margin-bottom: 0px;
    padding: 0px;
    padding-top: 10px;
  }
  .header-options {
    flex: 0.7;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 0px;
  }
  .searchInput {
    width: 50vw;
  }
  .email {
    font-size: 12px;
  }
  .privacy-policy {
    font-size: 12px;
  }
  .multiple-choice-container {
    width: 70vw;
  }
  .share-span {
    font-size: 0.5rem;
    padding: 4px;
  }
  .about-btn {
    height: 36px;
    width: 36px;
  }
  .archive-btn {
    height: 36px;
    width: auto;

  }
  .archive-btn-container {
    width: 80vw;
  }
  .archive-db-btn {
    width: 20vw;
  }
  .answer {
    font-size: 0.8rem;
  }
  .who-btn {
    font-size: 0.5rem;
  }
}
